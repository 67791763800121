import axios from 'axios';

import Config from '@/config/config';

if (!Config.API_BASE_URL) {
  // eslint-disable-next-line no-console
  console.warn('API_URL is undefined');
}

export const publicApiInstance = axios.create({
  baseURL: Config.API_BASE_URL,
});

const authApiInstance = axios.create({
  baseURL: Config.API_BASE_URL,
});

export default authApiInstance;
