import { css } from '@emotion/react';

import { boxStyle, ColorPalette, fontSize, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';

const styles = {
  searchBar: css`
    cursor: pointer;
    width: 100%;
    border: ${boxStyle.border};
    border-radius: ${boxStyle.borderRadius};
    background-color: ${ColorPalette.white};
    padding: ${spacing['20']} ${spacing['10']};
    margin-top: ${spacing['16']};
    font-size: ${fontSize['16']};
    &:focus {
      outline: none;
      box-shadow: ${boxStyle.boxShadow};
    }
  `,
};

type SearchBarProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const SearchBar = ({ onChange, value }: SearchBarProps) => {
  const { t } = useTranslation();
  return (
    <input
      type="search"
      id="city-tour"
      aria-label={t('msg_searchbar_aria_label')}
      css={styles.searchBar}
      value={value}
      onChange={onChange}
      placeholder={t('msg_searchbar_placeholder')}
    />
  );
};

export default SearchBar;
