import { css } from '@emotion/react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce';

import config from '@/config/config';
import { CITY_CARD_SIZE } from '@/config/constants';
import { media, mobile, spacing } from '@/config/style';
import useMedia from '@/hooks/useMedia';
import { useTranslation } from '@/hooks/useTranslation';
import { fetchAllCities, useAllCitiesQuery, useCitiesQuery } from '@/services/city';

import SearchBar from '../SearchBar/SearchBar';

import CityCard from './CityCard';

export const getServerSideProps: GetServerSideProps<{ dehydratedState: unknown }> = async () => {
  const queryClient = new QueryClient();

  try {
    await queryClient.prefetchQuery(['AllCities'], () => fetchAllCities());
  } catch (e) {
    return { notFound: true };
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

const styles = {
  searchBar: css`
    grid-column: span 2;
    grid-area: searchBar;
  `,
  contentWrapper: css`
    display: grid;
    gap: ${spacing['20']};
    grid-template-columns: repeat(4, ${CITY_CARD_SIZE}px);
    justify-content: center;
    grid-template-areas: '. searchBar searchBar .' 'cardsList cardsList cardsList cardsList';

    ${media.tablet} {
      display: flex;
      flex-direction: column;
    }
  `,
  cardsList: css`
    display: grid;
    grid-template-columns: repeat(4, ${CITY_CARD_SIZE}px);
    grid-area: cardsList;
    gap: ${spacing['20']};

    ${media.tablet} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  loadingSkeletonWrapper: css`
    grid-column: span 4;
    display: flex;
    justify-content: center;

    .skeleton {
      display: flex;
      gap: ${spacing['10']};
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  noDataWrapper: css`
    justify-content: center;
    display: flex;
    grid-column: span 4;
    margin-top: ${spacing['12']};
  `,
};

const renderLoadingSkeleton = (count: number) => {
  return (
    <div css={styles.loadingSkeletonWrapper}>
      <Skeleton count={count} height={CITY_CARD_SIZE} width={CITY_CARD_SIZE} containerClassName="skeleton" />
    </div>
  );
};

const HomeScreenContent = () => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput] = useDebounce(searchInput, 500);
  const citiesQuery = useCitiesQuery(debouncedSearchInput);
  const isMobile = useMedia(mobile);
  const allCitiesQuery = useAllCitiesQuery();

  const cities = citiesQuery.data?.pages.flatMap((page) => page.results) || [];

  const renderCardsList = () => {
    if (!cities.length) {
      return <div css={styles.noDataWrapper}>{t('msg_city_tour_not_found')}</div>;
    }
    return cities.map((city) => <CityCard city={city} key={city.id} />);
  };

  const renderInfiniteScroll = () => {
    if (citiesQuery.isLoading || allCitiesQuery.isLoading) {
      return renderLoadingSkeleton(isMobile ? 1 : 4);
    }

    return (
      <InfiniteScroll
        css={styles.cardsList}
        loadMore={() => citiesQuery.fetchNextPage()}
        hasMore={citiesQuery.hasNextPage}
        loader={renderLoadingSkeleton(isMobile ? 1 : 4)}
      >
        {renderCardsList()}
      </InfiniteScroll>
    );
  };

  if (!allCitiesQuery.data) {
    return <div css={styles.noDataWrapper}>{t('msg_error_something_went_wrong')}</div>;
  }

  const numberOfCities = allCitiesQuery.data.filter((city) => !city.is_coming_soon).length;

  return (
    <div css={styles.contentWrapper}>
      <div css={styles.searchBar}>
        <NextSeo
          title={t('msg_seo_home_page_title')}
          description={t('msg_seo_home_page_description', { city_amount: numberOfCities })}
          openGraph={{
            url: config.SITE_URL,
            title: t('msg_seo_home_page_title_og'),
            description: t('msg_seo_home_page_description_og', { city_amount: numberOfCities }),
          }}
        />
        <SearchBar value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
      </div>
      {renderInfiniteScroll()}
    </div>
  );
};

export default HomeScreenContent;
