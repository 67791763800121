import { QueryClient, useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

import endpoints from '@/config/endpoints';
import { publicApiInstance } from '@/utils/api';
import { PaginatedResult } from '@/utils/types';

export type BannerInfo = {
  id: number;
  title: string;
  description: string;
  blog_posts_link: string;
  button_text: string;
};

export type City = {
  id: number;
  title: string;
  tours: string;
  image: {
    full: string;
    thumbnail: string;
  };
  neighbourhoods: string;
  is_coming_soon: boolean;
  slug: string;
  country: {
    country_code: string;
    country_name: string;
  };
  is_suitable_for_displaying_places: boolean;
  banner_info?: BannerInfo;
};

export const fetchCities = ({
  city_country_search,
  limit,
  offset,
}: {
  city_country_search?: string;
  limit: number;
  offset: number;
}) => {
  return publicApiInstance.get<PaginatedResult<City>>(endpoints.CITIES, {
    params: { city_country_search, limit, offset },
  });
};

export const fetchCityIdBySlug = (queryClient: QueryClient, slug: string) => {
  return queryClient.fetchQuery({
    queryKey: ['CityIdBySlug', slug],
    queryFn: () =>
      publicApiInstance.get<{ id: number; slug: string }>(endpoints.CITY_SLUG_TRANSLATOR(slug)).then((res) => res.data),
    staleTime: 120 * 60 * 1000,
  });
};

export const fetchAllCities = () => {
  return publicApiInstance.get<City[]>(endpoints.CITIES, {
    params: { all: true },
  });
};

export const useAllCitiesQuery = () => {
  return useQuery({
    queryKey: ['AllCities'],
    queryFn: () => fetchAllCities().then((res) => res.data),
  });
};

const FETCH_LIMIT = 8;

export const useCitiesQuery = (city_country_search?: string, limit = FETCH_LIMIT) => {
  return useInfiniteQuery<PaginatedResult<City>>({
    queryKey: ['Cities', limit, city_country_search],
    queryFn: ({ pageParam = 0 }) => {
      return fetchCities({ city_country_search, limit, offset: pageParam }).then((res) => res.data);
    },
    getNextPageParam: ({ currentPage, offset, totalPages }) => {
      if (currentPage + 1 < totalPages) {
        return offset + limit;
      }
      return undefined;
    },
  });
};

export const fetchCity = (id: number) => publicApiInstance.get<City>(endpoints.CITY(id));

export const prefetchHomeScreenContent = (queryClient: QueryClient) => {
  return Promise.all([
    queryClient.prefetchQuery(['AllCities'], () => fetchAllCities().then((res) => res.data)),
    queryClient.prefetchInfiniteQuery(['Cities', FETCH_LIMIT, ''], () =>
      fetchCities({ city_country_search: '', limit: FETCH_LIMIT, offset: 0 }).then((res) => res.data),
    ),
  ]);
};

export const useCityQuery = (slug: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['City', slug],
    queryFn: async () => {
      const cityIdBySlug = await fetchCityIdBySlug(queryClient, slug);
      return fetchCity(cityIdBySlug.id).then((res) => res.data);
    },
  });
};

export enum CityViewType {
  tours = 'tours',
  attractions = 'attractions',
}
