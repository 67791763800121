import { useEffect, useState } from 'react';

const hdDimensions = {
  innerHeight: 1080,
  innerWidth: 1920,
  outerHeight: 1080,
  outerWidth: 1920,
};

const getSize = () => {
  if (typeof window !== 'undefined') {
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth,
    };
  }

  return hdDimensions;
};

// we need to make sure that version of the site received from the server corresponds to the version we have in client-side
// as both versions on first load have to correspond to each other (and on responsive cases it does not)
// we have initial state of dimensions of the window which will differ and it will force page reload
const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(hdDimensions);

  useEffect(() => {
    const measure = () => setDimensions(getSize());
    measure();

    window.addEventListener('resize', measure);

    return () => {
      window.removeEventListener('resize', measure);
    };
  }, []);

  return dimensions;
};

export default useWindowDimensions;
