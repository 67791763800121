import { css } from '@emotion/react';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Trans } from 'react-i18next';

import { bigDesktop, ColorPalette, fontWeight, media, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import AppStoreImg from '@/public/appStore.png';
import HeaderImage from '@/public/home-header.jpeg';
import PlayStoryImg from '@/public/playStore.png';

const containerHeight = 600;
const mobileContainerHeight = 400;

const styles = {
  imageWrapper: (src: string) => css`
    width: 100%;
    position: absolute;
    left: 0;
    min-height: ${containerHeight}px;
    background-image: url(${src});
    background-size: cover;
    background-position: center;
    background-color: ${ColorPalette.black_40};
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.mobile} {
      min-height: ${mobileContainerHeight}px;
    }
  `,
  title: css`
    color: ${ColorPalette.white};
    font-weight: ${fontWeight.light};
  `,
  description: css`
    color: ${ColorPalette.white};
    font-weight: ${fontWeight.light};
    margin-top: ${spacing['12']};
    line-height: 24px;
  `,
  contentWrapper: css`
    width: ${bigDesktop}px;
    padding: 0 ${spacing['32']};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.mobile} {
      padding-right: 0px;
    }
  `,
  appStore: css`
    margin-right: ${spacing['12']};
  `,
  storesWrapper: css`
    align-items: center;
    display: flex;
  `,
  container: css`
    min-height: ${containerHeight}px;

    ${media.mobile} {
      min-height: ${mobileContainerHeight}px;
    }
  `,
  lightTitlePart: css`
    font-weight: ${fontWeight.light};
  `,
  appStoresTitle: css`
    margin-top: ${spacing['10']};
    color: ${ColorPalette.white};
    font-weight: ${fontWeight.light};
  `,
  descriptionAndAppStoresWrapper: css`
    max-width: 300px;
    display: flex;
    flex-direction: column;
  `,
};

const HomeHeader = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.imageWrapper(HeaderImage.src)}>
        <div css={styles.contentWrapper}>
          <h1 css={styles.title}>
            <Trans
              i18nKey="msg_home_header_title"
              components={{
                strong: <strong />,
              }}
            />
          </h1>
          <div css={styles.descriptionAndAppStoresWrapper}>
            <span css={styles.description}>{t('msg_home_header_description')}</span>
            <span css={styles.appStoresTitle}>{t('msg_home_header_app_stores_title')}</span>
          </div>
          <div css={styles.storesWrapper}>
            <Link css={styles.appStore} href="https://apps.apple.com/lt/app/globetrott-travel-audio-guide/id1553250015">
              <Image src={AppStoreImg} width={118} height={38} alt="app-store" />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.globetrott">
              <Image src={PlayStoryImg} width={138} height={57} alt="play-store" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
