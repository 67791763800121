import { css } from '@emotion/react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import React from 'react';

import HomeHeader from '@/components/HomeScreenContent/HomeHeader';
import HomeScreenContent from '@/components/HomeScreenContent/HomeScreenContent';
import { ColorPalette, fontSize, fontWeight, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { prefetchHomeScreenContent } from '@/services/city';

const styles = {
  heading: css`
    text-align: center;
    margin: ${spacing['0']} auto;
    font-size: ${fontSize[32]};
    padding-top: ${spacing['32']};
    font-weight: ${fontWeight.light};
    color: ${ColorPalette.primary};
    font-family: Lovelo;
  `,
};
const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HomeHeader />
      <h1 css={styles.heading}> {t('msg_card_title')}</h1>
      <HomeScreenContent />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<{ dehydratedState: unknown }> = async () => {
  const queryClient = new QueryClient();

  await prefetchHomeScreenContent(queryClient);

  return {
    props: {
      // There is an issue with prefetchInfiniteQuery with next js SSR.
      // @see https://github.com/tannerlinsley/react-query/issues/1458#issuecomment-747716357
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  };
};

export default HomePage;
