const endpoints = {
  CITIES: `/api/travel/cities/`,
  TOURS: (cityId: number) => `/api/travel/cities/${cityId}/tours/`,
  TOURS_INTERESTS: `/api/travel/tags/interests/`,
  REVIEWS: (tourId: number) => `/api/travel/tours/${tourId}/reviews/`,
  TOUR: (cityId: number, tourId: number) => `/api/travel/cities/${cityId}/tours/${tourId}/`,
  TOURS_SITES: (cityId: number, tourId: number) => `/api/travel/cities/${cityId}/tours/${tourId}/sites/`,
  TOUR_INFO: (tourId: number) => `/api/travel/tours/${tourId}/`,
  TOUR_REVIEW_SUMMARY: (tourId: number) => `/api/travel/tours/${tourId}/reviews/summary/`,
  APP_CONFIG: '/api/manifests/app-config/',
  CITY: (id: number) => `/api/travel/cities/${id}/`,
  CITY_SLUG_TRANSLATOR: (slug: string) => `/api/travel/translate-slug/city/${slug}/`,
  TOUR_SLUG_TRANSLATOR: (slug: string) => `/api/travel/translate-slug/tour/${slug}/`,
  CITY_ATTRACTIONS: (city_id: number) => `/api/travel/cities/${city_id}/entities/`,
  CITY_ENTITY_TAGS: (city_id: number) => `/api/travel/cities/${city_id}/entities/tags/`,
  ATTRACTION_SLUG_TRANSLATOR: (slug: string) => `/api/travel/translate-slug/entity/${slug}/`,
  CITY_ATTRACTION_BY_ID: (city_id: number, attraction_id: number) =>
    `/api/travel/cities/${city_id}/entities/${attraction_id}/`,
};

export default endpoints;
